import * as React from 'react'

import Modal from 'react-modal'

import { format } from 'date-fns'
import { de } from 'date-fns/locale'

import { DateRange, DayPicker, DateUtils} from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import './day-picker.css'

// https://github.com/reactjs/react-modal
// https://react-day-picker.js.org/
// https://stackoverflow.com/questions/37949981/call-child-method-from-parent

const [hours, minutes] = [12,0]

class EditReservierung extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      selected_range: {
        from: null,
        to: null
      }
    }

    this.openEditPanel = this.openEditPanel.bind(this)
    this.openCreatePanel = this.openCreatePanel.bind(this)
    this.afterOpenPanel = this.afterOpenPanel.bind(this)

    this.closePanel = this.closePanel.bind(this)

    this.closeModalAndUpdateEvent = this.closeModalAndUpdateEvent.bind(this)
    this.closeModalAndDeleteEvent = this.closeModalAndDeleteEvent.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.updateRange = this.updateRange.bind(this)
    this.setRange = this.setRange.bind(this)

  }

  //-----------
  // Open Panel
  //-----------

  openEditPanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      },
      open: true
    })
  }

  openCreatePanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      },
      open: true
    })
  }

  afterOpenPanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    })
    // this.subtitle.style.color = '#f00'
  }

  //------------
  // Close Panel
  //------------

  closePanel() {
    this.setState({
      selected_range: {
        from: null,
        to: null
      }
    })
    this.props.onClosePanel()
    this.setState({
      open: false
    })
  }

  closeModalAndDeleteEvent() {
    if (confirm('Diese Reservierung wirklich löschen?')) {
      this.props.deleteEvent()
      this.closePanel()
    } else {
      return
    }
  }

  closeModalAndUpdateEvent() {
    if (this.state.selected_range.from && this.state.selected_range.to && this.state.event.anlass && this.state.event.anzahl>=0) {
      this.props.updateEvent()
      this.closePanel()
    } else {
      return
    }
  }

  //-------------
  // Update Event
  //-------------
  updateRange(range) {
    this.setState({
      selected_range: range
    })
    const vonDatum = new Date(
      range.from.getFullYear(),
      range.from.getMonth(),
      range.from.getDate(),
      hours,
      minutes
    )
    console.log("vonDatum: ", vonDatum)
    const bisDatum = new Date(
      range.to.getFullYear(),
      range.to.getMonth(),
      range.to.getDate(),
      hours,
      minutes
    )
    console.log("bisDatum: ", bisDatum)
    this.props.event.von = vonDatum
    this.props.event.bis = bisDatum
    this.setState({
      event: this.props.event
    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.props.event[name] = value
    this.setState({
      event: this.props.event
    })
  }

  setRange(range) {
    console.log("Range: ", range)
    if (range) {
      this.updateRange(range)
    } else {
      this.handleResetClick()
    }
  }

  handleResetClick() {
    const range = {from: null, to: null}
    this.updateRange(range)
  }

  //-------
  // Render
  //-------
  render() {
    const { from, to } = this.state.selected_range
    const modifiers = { start: from, end: to }
    return (
      <div>
        <Modal
          isOpen={this.state.open}
          onAfterOpen={this.afterOpenPanel}
          onRequestClose={this.closePanel}
          className='modal is-active'
          overlayClassName='Overlay'
          contentLabel='Edit Registrierung'
          ariaHideApp={false}
        >
          <div className='modal-background'></div>
          <div className='modal-content'>
            <header className='modal-card-head'>
              <p className='modal-card-title has-text-info' ref={subtitle => this.subtitle = subtitle}>
                {this.props.current_huette}
              </p>
              <button onClick={this.closePanel} className='delete' aria-label='schliessen'></button>
            </header>
            <section className='modal-card-body'>
              <h4 className='subtitle is-5'>
                Eingetragen von <b>{this.props.current_user}</b> am <b>{this.props.event.created_at}</b>
              </h4>
              <form className='edit_reservierung' id='edit_reservierung_1496' method='post'>
                <input name='utf8' type='hidden' value='✓'/>
                <input type='hidden' name='_method' value='patch'/>
                <input type='hidden' name='authenticity_token' value={this.props.event.authenticity_token}/>
                <div className='field'>
                  <label className='label' htmlFor='anlass'>Anlass:</label>
                  <div className='control'>
                    <input className='input' type='text' id='reservierung_anlass' value={this.props.event.anlass} name='anlass' onChange={this.handleInputChange}/>
                  </div>
                </div>
                <div className='field'>
                  <label className='label' htmlFor='anzahl'>Anzahl der Personen:</label>
                  <div className='control'>
                    <input className='input' type='number' id='reservierung_anzahl' value={this.props.event.anzahl} name='anzahl' onChange={this.handleInputChange}/>
                  </div>
                </div>
                <div className='HuettenDayRange py-3'>
                  {!from && !to && <h6 className='has-text-danger subtitle is-6' style={{lineHeight: "2.7rem"}}>
                    Bitte den Anreisetag selektieren.
                  </h6>}
                  {from && !to && <h6 className='has-text-success subtitle is-6' style={{lineHeight: "2.7rem"}}>
                    Bitte den <span className='has-text-danger'>Abreisetag</span> selektieren.
                  </h6>}
                  {from && to && <h6 className='has-text-success subtitle is-6' style={{lineHeight: "2.7rem"}}>
                    Reserviert vom {format(from, 'dd. MMM yyyy')} bis {format(to, 'dd. MMM yyyy')}
                    <button className='button is-pulled-right m-b-2' aria-label='Auswahl zurücksetzen' onClick={this.handleResetClick}>
                    Reset
                  </button>
                  </h6>}
                </div>
                <DayPicker
                  id='DayPicker'
                  mode='range'
                  weekStartsOn={1}
                  numberOfMonths={2}
                  locale={de}
                  defaultMonth={this.props.from}
                  month={from ? new Date(from) : new Date()}
                  selected={{from,to}}
                  onSelect={this.setRange}
                  modifiers={modifiers}
                />
              </form>
            </section>
            <footer className='modal-card-foot'>
              <button value='Save' className='button is-success' aria-label='sichern' onClick={this.closeModalAndUpdateEvent}>Sichern</button>
              <button value='Delete' className='button is-danger' aria-label='löschen' onClick={this.closeModalAndDeleteEvent}>Löschen</button>
              <button value='Close' className='button' aria-label='schliessen' onClick={this.closePanel}>Schliessen</button>
            </footer>
          </div>
        </Modal>
      </div>
    )
  }
}

export default EditReservierung

import * as React from 'react'
import { createRef } from 'react'

import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import de from 'date-fns/locale/de'
import { Controller } from '@hotwired/stimulus'

// https://www.coursesxcasey.com/blog/using-axios-for-ajax-calls-with-ruby-on-rails-7-and-turbo
import axiosClient from '../../../libs/axios_client'

import { HutEvent } from './HutEvent'
import { CustomMonthDateHeader } from './MonthDateHeader'
import EditReservierung from './EditReservierung'
import InfoReservierung from './InfoReservierung'

import 'react-big-calendar/lib/css/react-big-calendar'
// https://react-typescript-cheatsheet.netlify.app/docs/basic/setup

const { differenceInDays } = require("date-fns")
const { addDays } = require("date-fns")

const locales = {
  'de': de,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const messages = {
  allDay: 'Tag',
  previous: 'Vorheriger',
  next: 'Nächster',
  today: 'Aktueller Monat',
  month: 'Monat',
  week: 'Woche',
  day: 'Tag',
  agenda: 'Agenda',
  date: 'Datum',
  time: 'Zeit',
  event: 'Ereignis'
}

const mapToRBCFormat = e => Object.assign({}, e, {
  von: new Date(e.von),
  bis: new Date(e.bis)
})

Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1
  var dd = this.getDate()

  return [this.getFullYear(),
          (mm>9 ? '-' : '-0') + mm,
          (dd>9 ? '-' : '-0') + dd
         ].join('')
}

// const dateFormat = /^\d{4}:\d{2}:\d{2}T\d{2}-\d{2}-\d{2}/

// function reviver(value) {
//     if (typeof value === 'string' && dateFormat.test(value)) {
//         return new Date(value)
//     }
//     return value
// }

// Style of the Events
function eventStyleGetter(event) {
  var backgroundColor
  if (event.anzahl > 0) {
    backgroundColor =  '#19758c'
  } else {
    backgroundColor =  '#b51f1f'
  }
  return {
      style: {
        backgroundColor: backgroundColor
    }
  }
}
class HuettenKalender extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedDate: this.props.date,
      events: this.props.reservierungen,
      booking_list: this.props.booking_list,
      current_user: this.props.current_user,
      current_user_id: this.props.current_user_id,
      current_user_role: this.props.current_user_role,
      current_huette: this.props.current_huette,
      current_huette_id: this.props.current_huette_id,
      selected_event: {id: null,huette: null,huette_id: null,user: null,user_id: null,created_at: null,anlass: null,von: null,bis: null,anzahl: null, user_role: null},
      editable: false
    }
    this.openEditPanel = this.openEditPanel.bind(this)
    this.openInfoPanel = this.openInfoPanel.bind(this)
    this.openCreatePanel = this.openCreatePanel.bind(this)
    this.changeMonth = this.changeMonth.bind(this)
  }
  editRef = createRef()
  infoRef = createRef()
  
  render() {
    const isLoggedIn = (this.props.current_user_role >= 5)
    const MonthDateHeader = ({ label, date }) => {
      return <CustomMonthDateHeader
        label = {label}
        count = {this.state.booking_list[date.yyyymmdd()]}
        maxCount = {this.state.current_huette_id == 2 ? 30 : 17}
      />
    }

    return (
      <div>
        <Calendar
          localizer={localizer}
          culture={'de'}
          date={new Date(this.state.selectedDate)}
          events={this.state.events.map(mapToRBCFormat)}
          startAccessor='von'
          endAccessor='bis'
          views={['month']}
          step={60}
          selectable
          popup={true}
          // onNavigate={date => {this.setState({ selectedDate: date })}}
          onNavigate={date => this.changeMonth(date)}
          onSelectEvent={event => (isLoggedIn && (this.props.current_user_id == event.user_id || this.props.current_user_role > event.user_role)) ? this.openEditPanel(event) : this.openInfoPanel(event)}
          onSelectSlot={slotInfo => isLoggedIn ? this.openCreatePanel(slotInfo) : null}
          eventPropGetter={eventStyleGetter}
          components={{
            month: {
              event: HutEvent,
              dateHeader: MonthDateHeader
            }
          }}
          messages={messages}
        />
        <EditReservierung
          ref={this.editRef}
          event={this.state.selected_event}
          current_user={this.props.current_user}
          current_user_id = {this.props.current_user_id}
          current_huette={this.props.current_huette}
          current_huette_id = {this.props.current_huette_id}
          updateEvent = {this.updateEvent.bind(this)}
          deleteEvent = {this.deleteEvent.bind(this)}
          onClosePanel = {this.onClosePanel.bind(this)}
        />
        <InfoReservierung
          ref={this.infoRef}
          event = {this.state.selected_event}
          onClosePanel = {this.onClosePanel.bind(this)}
          current_user = {this.props.current_user}
          current_user_id = {this.props.current_user_id}
          current_huette = {this.props.current_huette}
          current_huette_id = {this.props.current_huette_id}
        />
        {/*<pre>{JSON.stringify(this.props,0,4)}</pre>
        <pre>{JSON.stringify(this.state.events,0,4)}</pre>*/}
      </div>
    )
  }

  openEditPanel(event) {
    this.setState({selected_event: event})
    this.editRef.current.openEditPanel()
  }

  openInfoPanel(event) {
    this.setState({selected_event: event})
    this.infoRef.current.openInfoPanel()
  }

  openCreatePanel(slotInfo) {
    this.setState({
      selected_event: {
        id: null,
        huette: this.props.current_huette,
        huette_id: this.props.current_huette_id,
        user: this.props.current_user,
        user_id: this.props.current_user_id,
        created_at: slotInfo[0],
        anlass: '',
        von: slotInfo.start,
        bis: addDays(slotInfo.start, (differenceInDays(slotInfo.end, slotInfo.start)-1)),
        anzahl: ''
      }
    })
    this.editRef.current.openCreatePanel()
    // console.log(JSON.stringify(this.state))
  }
 
  onClosePanel() {
    this.setState({selected: false})
  }

  // ------------
  // change month 
  // ------------
  changeMonth(date) {
    // console.log('changeMonth')
    const huette_id = this.props.current_huette_id
    axiosClient.post('/reservierungen/changeMonth', {
      date: date,
      huette_id: huette_id
    })
    .then((response) => {
      // console.log('Response: '+JSON.stringify(response))
      let answer = []
      let booking = []
      answer = response.data.huette
      booking = response.data.booking_list
      // console.log('Belegung: '+JSON.stringify(answer))
      // console.log('Anzahl: '+JSON.stringify(booking))
      this.setState({events: answer, booking_list: booking, selectedDate: response.data.date})
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }

  // ------------
  // update event 
  // ------------
  updateEvent = () => {
    const event = this.state.selected_event
    axiosClient.post('/reservierungen', {
      reservierung: {
        id: event.id ? event.id : null,
        huette_id: event.huette_id ? event.huette_id : this.props.current_huette_id,
        user_id: this.props.current_user_id,
        anlass: event.anlass,
        von: event.von,
        bis: event.bis,
        anzahl: event.anzahl
      }
    })
    .then(response => {
      // console.log('Response: '+JSON.stringify(response))
      let answer = []
      let booking = []
      answer = response.data.huette
      booking = response.data.booking_list
      // console.log('Data Hut: '+JSON.stringify(answer))
      // console.log('Answer: '+JSON.stringify(booking))
      this.setState({events: answer, event: null, selected: null, booking_list: booking})
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }

  // ------------
  // delete event 
  // ------------
  deleteEvent = () => {
    const huette_id = this.props.current_huette_id
    axiosClient.delete('/reservierungen/'+this.state.selected_event.id)
    .then(response => {
      let answer = []
      let booking = []
      answer = response.data.huette
      booking = response.data.booking_list
      // console.log('Data Hut: '+JSON.stringify(answer))
      // console.log('Answer: '+JSON.stringify(booking))
      this.setState({events: answer, event: null, selected: null, booking_list: booking})
    })
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }
}

export default HuettenKalender

import React from 'react'

export function CustomMonthDateHeader({ label, count, maxCount }) {
  if (Number(count) > 0) {
    if (count - maxCount > 0) {
      return (
        <div>
          {label}
          <span className={'count-of-bookings has-text-danger is-pulled-right is-hidden-touch'}>
            Zuviel: +{count-maxCount}
          </span>
          <span className={'count-of-bookings has-text-danger is-pulled-right is-hidden-desktop'}>
            +{count-maxCount}
          </span>
        </div>
      )
      } else if (count - maxCount == 0) {
      return (
        <div>
          {label}
          <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-touch'}>
            Voll: {maxCount}
          </span>
          <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-desktop'}>
            {maxCount}
          </span>
        </div>
      )
    } else {
      return (
        <div>
          {label}
          <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-touch'}>
            Frei: {maxCount-count}
          </span>
          <span className={'count-of-bookings has-text-info is-pulled-right is-hidden-desktop'}>
            {maxCount-count}
          </span>
        </div>
      )
    }
  // } else if (Number(count) == 0) {
  //   return (
  //     <div>
  //       {label}<br></br>
  //       <b className={'count-of-bookings has-text-danger'}>
  //         Geschlossen!
  //       </b>
  //     </div>
  //   )
  } else {
    return (
      <div>
        {label}
        {/* <br></br>
        <b className={'count-of-bookings has-text-info'}>
          Frei {count}
        </b> */}
      </div>
    )
  }
}
import axios from "axios"

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})

instance.interceptors.request.use((config) => {
  config.headers["X-CSRF-Token"] =
    document.getElementsByName("csrf-token")[0].content
  return config
})

export default instance
import React from 'react'

export function HutEvent({ event }) {
  return (
    <div>
      <span className={'event-anlass'}>
        {event.anlass}
      </span>
      <span className={(event.anzahl > 0 ? 'is-pulled-right' : 'is-hidden')}>
        {event.anzahl} <i className="fa fa-user"></i>
      </span>
    </div>
  )
}
import ReactOnRails from 'react-on-rails'
import HuettenKalender from '../bundles/HuettenKalender/components/HuettenKalender'
import HuettenStatistik from '../bundles/HuettenKalender/components/HuettenStatistik'

ReactOnRails.setOptions({
  traceTurbolinks: true,
});

ReactOnRails.register({
  HuettenKalender,
  HuettenStatistik
})

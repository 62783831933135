import * as React from 'react'

import Modal from 'react-modal'

import { format } from 'date-fns'
import { de } from 'date-fns/locale'

require('moment/locale/de')

class InfoReservierung extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }

    this.openInfoPanel = this.openInfoPanel.bind(this)
    this.openCreatePanel = this.openCreatePanel.bind(this)
    this.afterOpenPanel = this.afterOpenPanel.bind(this)

    this.closePanel = this.closePanel.bind(this)
  }

  //-----------
  // Open Panel
  //-----------

  openInfoPanel() {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    })
    this.setState({
      open: true
    })
  }

  openCreatePanel()  {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    })
    this.setState({
      open: true
    })
  }

  afterOpenPanel()  {
    this.setState({
      selected_range: {
        from: new Date(this.props.event.von),
        to: new Date(this.props.event.bis)
      }
    })
    // this.subtitle.style.color = '#f00'
  }

  //------------
  // Close Panel
  //------------

  closePanel()  {
    this.props.onClosePanel()
    this.setState({
      open: false
    })
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.open}
          onAfterOpen={this.afterOpenPanel}
          onRequestClose={this.closePanel}
          className='modal is-active'
          overlayClassName='Overlay'
          contentLabel='Info Registrierung'
          ariaHideApp={false}
        >
          <div className='modal-background'></div>
          <div className='modal-content'>
            <header className='modal-card-head'>
              <p className='modal-card-title has-text-info' ref={subtitle => this.subtitle = subtitle}>
                {this.props.current_huette}
              </p>
              <button onClick={this.closePanel} className='delete' aria-label='schliessen'></button>
            </header>
            <section className='modal-card-body'>
              <h4 className='title is-5'>{this.props.event.anlass}</h4>
              { this.props.event.anzahl > 0
                ? <h4 className='subtitle is-6'>Anzahl der Personen: <b>{this.props.event.anzahl}</b></h4>
                : ''
              }
              <h5 className='subtitle is-6'>Reserviert vom <b>{format(new Date(this.props.event.von), 'dd. MMM yyyy')}</b> bis <b>{format(new Date(this.props.event.bis), 'dd. MMM yyyy')}</b></h5>
              <h5 className='subtitle is-6'>
                Eingetragen von <b>{this.props.event.user}</b> am <b>{this.props.event.created_at}</b>
              </h5>
            </section>
            <footer className='modal-card-foot'>
              <button value='Close' className='button' aria-label='schliessen' onClick={this.closePanel}>Schliessen</button>
            </footer>
          </div>
        </Modal>
      </div>
    )
  }
}

export default InfoReservierung
